<template>
    <div>
        <base-header :base-title="$t('loyaltyTier.title')"></base-header>
        <!-- begin:: Content -->
        <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <base-title :head-title="headTitle"></base-title>
                    <div class="kt-portlet__head-toolbar">
                        <div class="kt-portlet__head-wrapper">
                            <template
                                v-if="checkPermission('LoyaltyLoyaltyTierResource.POST.store')">
                                &nbsp;&nbsp;
                                <base-button-modal
                                    :button-text="modalText"
                                    :button-icon="modalIcon"
                                    :button-name="modalName"
                                    :onButtonModalClicked="showModal"
                                ></base-button-modal>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="kt-portlet__body">
                    <crud-table
                        ref="crudTable"
                        :table-options="tableOptions"
                        :repository="repository"
                        :permission="permission"
                        :event="fetch"
                        @on-click-edit="onClickEdit"
                        @on-click-delete="onClickDelete"
                        @on-click-view="onClickView"
                    ></crud-table>
                </div>
                <form-modal v-if="isShowModal" @event="fetch"></form-modal>
            </div>
        </div>
        <!-- end:: Content -->
    </div>
</template>

<script>
import SweetAlertTemplate from "./../../../components/_general/SweetAlert";
import BaseTitle from "./../../../components/_base/BaseTitle";
import BaseHeader from "./../../../components/_base/BaseHeader";
import BaseButtonModal from "./../../../components/_base/BaseButtonModal";
import LoadSpinner from "./../../../components/_general/LoadSpinner";
import CrudTable from "./../base/crud-table/crudTable";
import CheckPermission from "./../../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import { RepositoryFactory } from "./../../repositories/BaseRepositoryFactory";
import { mapState, mapMutations, mapActions } from "vuex";
const loyaltyTierRepository = RepositoryFactory.get("loyaltyTierRepository");

import BlockUI from './../../../components/_js/BlockUI';
const blockUI = new BlockUI();
const SweetAlert = Vue.extend(SweetAlertTemplate);

Vue.component("ColumnCardContent", {
    props: ["data", "index", "column"],
    template: '<div class="scale-wrapper-card"><html ><body><div v-html="data.card_content" class="scale-wrapper"></div></body></html></div>',
    methods: {},
    mounted() {}
});
Vue.component("ColumnCardThumbnail", {
    props: ["data", "index", "column"],
    template: '<div class="scale-wrapper-card"><html ><body><div v-html="data.card_thumbnail" class="scale-wrapper"></div></body></html></div>',
    methods: {},
    mounted() {}
});
Vue.component("ColumnDescription", {
    props: ["data", "index", "column"],
    template: '<p v-html="data.descriptions"></p>',
    methods: {},
    mounted() {}
});
Vue.component("ColumnColor", {
    props: ["data", "index", "column"],
    template: `<div>
                    <div :style="dynamicStyle"></div>
                    <p>{{data.color}}</p>
                </div>`,
    computed: {
        dynamicStyle(){
            return {
                "background-color":this.data.color,
                "line-height":"3.5rem",
                "border":"1px solid #e3e3e3",
                "border-radius":".5rem",
                "padding-left":"1rem",
                "min-height":"3.5rem",
                "min-width":"3.5rem",
            }
        }
    },
    mounted() {}
});

export default {
    components: {
        CrudTable,
        BaseTitle,
        BaseHeader,
        BaseButtonModal,
        FormModal: () => ({
            component: new Promise(function(resolve, reject) {
                setTimeout(function() {
                    resolve(import("./FormModal.vue"));
                }, 1000);
            }),
            loading: LoadSpinner
        })
    },
    data() {
        var vx = this;
        return {
            headTitle: this.$t('loyaltyTier.title'),
            modalText: this.$t('loyaltyTier.add'),
            modalIcon: "flaticon2-add-1",
            repository: loyaltyTierRepository,
            permission: {
                edit: this.checkPermission('LoyaltyLoyaltyTierResource.PUT.update.Integer.key'),
                delete: this.checkPermission('LoyaltyLoyaltyTierResource.DELETE.destroy.Integer.key'),
                view: this.checkPermission('LoyaltyLoyaltyTierResource.GET.show.Integer.key')
                },
            tableOptions: {
                name: "CRUD_TABLE",
                columns: [
                    'id',"name", "minimum", "rdescription", 'rcolor',
                    'cardContent',
                    'cardThumbnail',
                    'point_duration',"actions"
                ],
                sortable: [
                     'id',
                    "name",
                    "minimum",
                    'rdescription',
                    'rcolor',
                    // 'cardContent',
                    // 'cardThumbnail',
                    'point_duration'
                ],
                orderBy: { column: 'id', ascending:false },
                customColumn: {
                    rdescription: 'descriptions',
                    rcolor: 'color',
                    cardContent: 'card_content',
                    cardThumbnail: 'card_thumbnail',
                },
                templates: {
                    cardContent: "ColumnCardContent",
                    cardThumbnail: "ColumnCardThumbnail",
                    rcolor: "ColumnColor",
                    rdescription: 'ColumnDescription',
                },
                headings: {
                    id: "ID",
                    name: this.$t('common.name'),
                    minimum: this.$t('loyaltyTier.minimum_loyalty'),
                    rdescription: this.$t('loyaltyTier.description'),
                    rcolor: this.$t('loyaltyTier.color'),
                    cardContent: this.$t('common.card'),
                    cardThumbnail: this.$t('loyaltyTier.card_thumbnail'),
                    point_duration: this.$t('loyaltyTier.point_duration'),
                },
                columnsFilter: [
                    {
                        key: "id",
                        type: "number"
                    },
                    {
                        key: "name",
                        type: "text"
                    },
                    {
                        key: "minimum",
                        type: "number"
                    },
                    {
                        key: "rdescription",
                        type: "text"
                    },
                    {
                        key: "rcolor",
                        type: "text"
                    },
                    {
                        key: "point_duration",
                        type: "number"
                    }
                ]
            }
        };
    },
    watch: {
        isShowModal: function(isTrue) {
            if (!isTrue) {
                this.setEditData(null);
                let modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
                if(!_.isUndefined(modalBackdrop)){
                    modalBackdrop.parentNode.removeChild(modalBackdrop);
                }
            }
        },
        shouldRefreshTable: function(newValue, oldValue) {
            if (newValue === true) {
                this.$refs.crudTable.refreshTable();
            }
        }
    },
    computed: {
        ...mapState("Crud", {
            modalName: state => state.modalName,
            isLoaded: state => state.isLoaded,
            table: state => state.table,
            isShowModal: state => state.isShowModal,
            showModalMode: state => state.showModalMode,
            shouldRefreshTable: state => state.shouldRefreshTable,
        })
    },
    methods: {
        ...mapMutations({
            setRepository: 'Crud/SET_REPOSITORY',
            refreshData: 'Crud/REFRESH_TABLE',
            showModal:'Crud/SHOW_MODAL',
            hideModal:'Crud/HIDE_MODAL',
            setModal: 'Crud/SET_MODAL',
            setupTable: 'Crud/SETUP_TABLE',
            setEditData: 'Crud/SET_EDIT_DATA',
            setViewData: 'Crud/SET_VIEW_DATA',
        }),
        checkPermission(method) {
            return checkPermission.isCanAccess(method);
        },
        // showModal() {},
        fetch(){
            this.refreshData(true);
            this.hideModal();
        },
        onClickEdit(data) {
            this.$store.commit("Crud/SET_EDIT_DATA",data);
            this.$store.commit("Crud/SHOW_MODAL");
        },
        onClickDelete(data) {
            // console.log(data);
            // let instance = new SweetAlert().showInfo({
            //     swalTitle: "",
            //     swalText: "Tier terendah tidak bisa dihapus",
            //     swalType: "warning",
            //     onConfirmButton: function() {}
            // });
            this.$store.dispatch("Crud/deleteById", {
                deleteRepository: loyaltyTierRepository.delete,
                id: data.id,
                name: data.name,
                message: 'Data Tier <b>' + data.name + '</b> akan terhapus. Member dengan Tier tersebut akan disesuaikan sesuai minimum tier yang terdsedia'
            });
        },
        onClickView(data) {
            this.$store.commit("Crud/SET_VIEW_DATA",data);
            this.$store.commit("Crud/SHOW_MODAL");
        }
    },
    created() {
        this.setRepository(loyaltyTierRepository)
        this.setModal('LoyaltyTierModal')
        this.hideModal();
    },
    mounted() {}
};
</script>
